<template>
  <div class="loader" v-if="loading"></div>
  <div v-if="mydigitalmsg" class="alert alert-danger px-2" role="alert">
    {{ mydigitalmsg }}
  </div>
  <div class="login-form justify-content-center align-middle">
    <div class="section mb-5">
      <img src="img/logouitm-transparent.png" alt="image" class="form-image">
    </div>
    <div class="section mb-3">
      <img src="img/logomystudent-transparent.png" alt="image" style="max-width: 75vw;max-height:120px;">
    </div>
    <div class="section mx-3 p-2 wide-block" v-if="specialLogin">
      
      <p class="text-center">Log Masuk ini hanya untuk Pelajar Baru yang mendaftar pada 23-24 Sep 2023 di UiTM Kampus Shah Alam sahaja.  Jika anda bukan di kategori ini sila <a href="#" @click="showLoginForm">kembali</a></p>
      <div v-if="message" class="alert alert-danger px-2" role="alert">
        {{ message }}
      </div>
      <form v-on:submit.prevent="handleLoginSpecial" novalidate>
        <div class="form-group boxed">
          <div class="input-wrapper">
            <input type="text" class="form-control" id="studentno" name="studentno" placeholder="No. Pelajar" required v-model="auth.studentno">
            <i class="clear-input">
              <ion-icon name="close-circle"></ion-icon>
            </i>
          </div>
        </div>
        
        <div class="form-group boxed">
          <div class="input-wrapper">
            <input type="password" class="form-control" id="icno" name="icno" placeholder="6 Digit Terakhir MyKAD" autocomplete="off" required  v-model="auth.icno">
            <small id="emailHelp" class="form-text text-muted">Sila masukkan 6 digit terakhir No. Kad Pengenalan</small>
          </div>
          
        </div>
        
        
        <button type="submit" class="btn btn-info btn-block btn-lg" style="max-width: 80vw !important;max-height:90px !important;">Log in</button>
        
        
      </form>
    </div>
    <div class="section mx-3 p-2 wide-block" v-else>
      <ul class="nav nav-tabs style1" role="tablist">
        <li class="nav-item">
          <a class="nav-link loginnav active" data-bs-toggle="tab" href="#google-tab" role="tab">
            Google
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" href="#istudent-tab" role="tab">
            iStudent
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" href="#sso-tab" role="tab">
            UiTM&nbsp;SSO
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" href="#mydigitalid-tab" @click="handleMydigitalidLogin" role="tab">
            MyDigitalID
          </a>
        </li>
      </ul>
      <div class="tab-content mt-2">
        
        <div class="tab-pane fade active show" id="google-tab" role="tabpanel">
          <img src="img/btn_google_signin_light_focus_web.png"  @click="handleGoogleLogin" style="cursor: pointer; "
          alt="image" class="loginbtn" >
          <div class="section mb-12 p-2">
            <a class="p-2 btn btn-sm btn-app bg-info" href="https://mail.uitm.edu.my" target="_blank">Tatacara Pengaktifan Google UiTM</a>
          </div>
        </div>
        <div class="tab-pane fade" id="mydigitalid-tab" role="tabpanel">
          <div class="logo">
            <img src="img/logo_mydigitalid.png" alt="Logo" />
          </div>
          <h1>MyDigital ID Login</h1>
          <div class="notification">
            Your QR code will expire in:
            <span>{{ countdown }}</span>
          </div>
          <div class="qr-code" ref="qrcode"></div>
          <div class="links">
            <p v-if="isMobileDevice">Click QR Image for mobile device</p>
            <p style="font-size: small;">{{ notaUpd }}</p>
          </div>
        </div>
        <div class="tab-pane fade" id="istudent-tab" role="tabpanel">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
          
          <form v-on:submit.prevent="handleLogin" novalidate>
            <div class="form-group boxed">
              <div class="input-wrapper">
                <input type="text" class="form-control" id="username" name="username" placeholder="Student No." required v-model="input.username">
                <i class="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
            </div>
            
            <div class="form-group boxed">
              <div class="input-wrapper">
                <input type="password" class="form-control" id="password" name="password" placeholder="Password" autocomplete="off" required  v-model="input.password">
                <i class="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
            </div>
            
            
            <button type="submit" class="btn btn-info btn-block btn-lg" style="max-width: 80vw !important;max-height:90px !important;">Log in</button>
            
            
          </form>
        </div>
        <div class="tab-pane fade" id="sso-tab" role="tabpanel">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
          
          <form v-on:submit.prevent="handleLogin" novalidate>
            <div class="form-group boxed">
              <div class="input-wrapper">
                <input type="text" class="form-control" id="username_sso" name="username_sso" placeholder="Student No." required v-model="input.username">
                <i class="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
            </div>
            
            <div class="form-group boxed">
              <div class="input-wrapper">
                <input type="password" class="form-control" id="password_sso" name="password_sso" placeholder="Password" autocomplete="off" required  v-model="input.password">
                <i class="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
            </div>
            
            
            <button type="submit" class="btn btn-info btn-block btn-lg" style="max-width: 80vw !important;max-height:90px !important;">Log in</button>
            
            
          </form>
          <!-- <a href="https://sso.uitm.edu.my/nidp/oauth/nam/authz?client_id=a8b9b0c5-a0d0-4a6b-85f6-b0b794acba76&response_type=code&resourceServer=Identity%20Provider&redirect_uri=https://mystudent.uitm.edu.my/websso/callback&scope=profile&state=oauthcode" class="btn btn-block p-2 btn-outline-info rounded shadowed me-1 mb-1" style="max-width: 80vw;max-height:90px;"><img src="img/uitm.svg" alt="" height="34" style="m-3"> Sign in with UiTM SSO</a> -->
        </div>
      </div>
    </div>
    <!-- <div class="section p-2">
      <button class="btn btn btn-app bg-primary" @click="showLoginForm" v-if="!specialLogin">Log Masuk Pelajar Baharu</button>
    </div> -->
    
    <div class="section mb-2 fixed-bottom">
      <span class="badge badge-info mb-1">MyStudent App v1.1.1</span><br>
      <span>&copy; 2024 Universiti Teknologi MARA</span>
    </div>
  </div>
  <div class="modal" id="ModalGuide" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Cara Pengaktifan akaun Gmail UiTM </h5>
        </div>
        <div class="modal-body">
          <p> Bagi pelajar yang baru mendaftar sebagai pelajar, emel sedang diwujudkan. Untuk pengaktifan akaun emel pelajar baharu, username dan password akan dihantar ke alamat emel alternative yang didaftarkan pada Student Portal. Mohon semak dari semasa ke semasa emel yang dihantar dari "STUDENT EMAIL ADMINISTRATOR (adminpelajar@uitm.edu.my)". 
          </p> 
        </div>
        <div class="modal-footer">
          <a href="https://mail.uitm.edu.my/images/2022/08/12/studentguide_landscap.png" target="_blank" class="btn btn-primary">Cara Pengaktifan</a>
          <a href="#" data-bs-dismiss="modal">Close</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {app,db} from '../firebase';
import QRCode from 'qrcodejs2';
import { doc, setDoc, Timestamp, getDoc } from "firebase/firestore";
import {
  signInWithPopup,
  //signInWithRedirect,'
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  getAuth,
  signInWithCustomToken,
} from "firebase/auth";

const auth = getAuth(app);
export default {
  data() {
    return {
      COMPLETE_FLOW_ctr: 0,
      nonce: null,
      wss: null,
      time: 120,
      isDesktop: false,
      notaUpd: '',
      countdown: '00:02:00',
      loading:false,
      message: "",
      mydigitalmsg: "",
      timeout: null,
      timeoutDuration: 5000,
      fallbackUrl: 'https://apps.apple.com/my/app/mydigital-id/id1435289143',
      specialLogin: false,
      verndor_list: ['930111065175', '931018016920', '840105135326', '760615715080', '910502065078', '941008146309', '940705025735', '770616015843', '000402100195', '960426035656', '011128140058', '001212140887', '000110100468', '920819145059', '990626106567'],
      input:{
        username: "",
        password: ""
      },
      auth: {
        studentno: "",
        icno: ""
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isMobileDevice() {
      return /Mobi|Android/i.test(navigator.userAgent);
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  beforeDestroy() {
    // Clear the timeout if the component is destroyed
    clearTimeout(this.timeout);
  },
  mounted() {
    this.setFallbackUrl();
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  methods: {
    setFallbackUrl() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Check if the user is on iOS
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.fallbackUrl = 'https://apps.apple.com/my/app/mydigital-id/id1435289143';
      }
      // Check if the user is on Android
      else if (/android/i.test(userAgent)) {
        this.fallbackUrl = 'https://play.google.com/store/apps/details?id=my.mimos.signetclient';
      } else {
        // Fallback for other platforms (optional)
        this.isDesktop = true;
        this.fallbackUrl = 'https://example.com'; // Replace with a generic fallback URL if needed
      }
    },
    updateCountdown() {
      if (this.time > 0) {
        const minutes = Math.floor(this.time / 60).toString().padStart(2, '0');
        const seconds = (this.time % 60).toString().padStart(2, '0');
        this.countdown = `${minutes}:${seconds}`;
        this.time--;
      } else {
        this.time = 120;
        this.wss.close();
        // setInterval(this.updateCountdown, 1000);
        this.initializeWebSocket();
      }
    },
    generateNonce() {
      return crypto.randomUUID(); // Secure UUID
    },
    handleVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        clearTimeout(this.timeout);
      }
    },
    initializeWebSocket() {
      this.wss = new WebSocket("wss://mydigitalid.uitm.edu.my/");
      this.wss.onopen = () => {
        this.reconnectAttempts = 0;
        this.nonce = this.generateNonce();
        const authRequest = {
          action: 'AUTH_INFO',
          url: "wss://mydigitalid.uitm.edu.my/",
          app_name: 'MyStudent',
          nonce: this.nonce,
        };
        this.wss.send(JSON.stringify(authRequest));
      };
      this.wss.onmessage = (event) => {
        const data = JSON.parse(event.data);
        switch (data.action) {
          case 'loading':
          this.loading = true;
          console.log('Received loading from server');
          break;
          case 'AUTH_INFO':
          this.loading = false;
          this.handleAuthInfoResponse(data);
          break;
          case 'COMPLETE_FLOW':
          this.loading = false;
          this.handleCompleteFlowResponse(data);
          break;
          case 'USER_ERROR':
          this.loading = false;
          console.log("User/pass invalid");
          this.mydigitalmsg = "User/pass invalid. Please refresh and try again.";
          if (data.hasOwnProperty('msg')) {
            this.mydigitalmsg = data.msg;
          }
          break;
          // Handle other cases...
          default:
          console.log('Unexpected server response:', event);
        }
      };
      this.wss.onclose = (event) => {
        console.log('Disconnected from the WebSocket server');
        this.notaUpd = `Disconnected from WebSocket -> ${event.code} -> ${event.reason}`;
        if (event.code === 1006) {
          setTimeout(() => {
            this.initializeWebSocketCheck();
          }, 5000);
        }
      };
    },
    initializeWebSocketCheck() {
      this.wss = new WebSocket("wss://mydigitalid.uitm.edu.my/");
      
      this.wss.onopen = () => {
        console.log('ReConnected to the WebSocket server -> ',this.nonce);
        this.notaUpd = `ReConnected to the WebSocket server -> ${this.nonce}`;
        this.notaUpd = `Sila tunggu 5 saat untuk pengesahan`;
        this.wss.send(JSON.stringify({action:"AUTH_INFO_VALID_CHECK", nonce: this.nonce}));
      };
      this.wss.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log(`Client action ${data.action}`);
        switch (data.action) {
        case 'loading':
          this.loading = true;
          console.log('Received loading from server');
          break;
        case 'AUTH_INFO':
          this.loading = false;
          this.handleAuthInfoResponse(data);
          break;
        case 'COMPLETE_FLOW':
          this.loading = false;
          this.notaUpd = `Pengesahan berjaya`;

          this.handleCompleteFlowResponse(data);
          break;
          case 'USER_ERROR':
          this.loading = false;
          console.log("User/pass invalid");
          this.mydigitalmsg = "User/pass invalid. Please refresh and try again.";
          if (data.hasOwnProperty('msg')) {
            this.mydigitalmsg = data.msg;
          }
          this.handleMydigitalidLogin();
            break;
          // Handle other cases...
        default:
          console.log('Unexpected server response:', event);
        }
      };
      this.wss.onclose = function(event) {
        console.log('Disconnected from the WebSocket server');
        // Attempt to reconnect after a delay
        //setTimeout(connect, 2000); // Reconnect after 2 seconds
      };
    },
    handleCompleteFlowResponse(data) {
      this.COMPLETE_FLOW_ctr =+ 1;
      if (this.COMPLETE_FLOW_ctr>1){
        console.log('more than 1');
      }
      else {
        let that = this;
        signInWithCustomToken(auth,data._web_res.token)
        .then((result) => {
          console.log('user id ->',data._web_res.emel.split("@")[0]);
          const log = doc(db, 'mystudentlog', data._web_res.emel.split("@")[0]);
          setDoc(log, {
            loggedIn: "myDigitalID",
            browser: "User Agent = " + navigator.userAgent,
            loggedAt: Timestamp.now(),
          }, { merge: true });
          if (that.verndor_list.indexOf(data._web_res.nokp ) !== -1){
            console.log(`Success login by ${data.username}, icno: ${data._web_res.nokp}`);
            that.loading = false;
            that.mydigitalmsg = `Success login by ${data.username}, icno: ${data._web_res.nokp}`;
          }
          else {
            this.$store.dispatch("auth/login", data._web_res.emel.split("@")[0]).then(
              (result) => {
                if (result){
                  console.log('user id dispatch ->',data._web_res.emel.split("@")[0]);
                  this.$router.go("/");
                }
                
                else
                this.$router.push("/login");
              },
              (error) => {
                console.log('error user ->',data._web_res.emel.split("@")[0]);
                this.loading = false;
                this.message =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
              }
            );
          }
        }).catch((error) => {
          this.loading = false;
          this.message =
          (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message.replace('Firebase:', '') ||
          error.toString().replace('Firebase:', '');
        });
        //this.userInfo = `Authorization successful! ${data.username}, your ID number is ${data.idnumber}. It will redirect to your home page.`;
        //this.showThankYouPopup = true;
        // console.log(`Authorization successful! ${data.username}, your ID number is ${data.idnumber}. It will redirect to your home page.`);
        console.table(data);
      }
    },
    handleAuthInfoResponse(data) {
      const qrContent = {
        ...data,
        nonce: data.sessionId,
        sessionId: data.sessionId,
      };
      const qrContentBase64 = btoa(JSON.stringify(qrContent));
      const customLink = `misignet://mydigitalid?qr=${qrContentBase64}`;
      
      
      const linkElement = document.createElement('a');
      linkElement.addEventListener("click", (event) => {
        if (this.isDesktop === false){
          this.timeout = setTimeout(() => {
            window.location.href = this.fallbackUrl;
          }, this.timeoutDuration);
        }
        window.location.href = customLink;
        // Optionally, you can also alert the user
        // alert("Attempting to open the app...");
      });
      linkElement.href = customLink;
      linkElement.style.display = 'inline-block';
      new QRCode(linkElement, {
        text: JSON.stringify(qrContent),
        width: 256,
        height: 256,
      });
      if (this.$refs.qrcode){
        this.$refs.qrcode.innerHTML = ''; // Clear previous QR codes
        this.$refs.qrcode.appendChild(linkElement);
      }
      
      this.wss.send(JSON.stringify({ action: 'BIND_SESSION_BROWSER' }));
    },
    showLoginForm() {
      this.specialLogin = !this.specialLogin;
    },
    handleLogin() {
      this.loading = true;
      signInWithEmailAndPassword(auth,this.input.username+'@mystudent.uitm.edu.my', this.input.password)
      .then((result) => {
        const log = doc(db, 'mystudentlog', result.user.email.split("@")[0]);
        setDoc(log, {
          loggedIn: "iStudent",
          browser: "User Agent = " + navigator.userAgent,
          loggedAt: Timestamp.now(),
        }, { merge: true });
        
        
        this.$store.dispatch("auth/login", result.user.email.split("@")[0]).then(
        (result) => {
          if (result) {
            
            this.$router.go("/");
          }
          
          else
          this.$router.push("/login");
        },
        (error) => {
          this.loading = false;
          this.message =
          (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message.replace('Firebase:', '') ||
          error.toString().replace('Firebase:', '');
        }
        );
      }).catch((error) => {
        this.loading = false;
        this.message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message.replace('Firebase:', '') ||
        error.toString().replace('Firebase:', '');
      });
      
      /*axios
      .post('https://digitalcampus.uitm.edu.my/api/sso/login', {
      username: this.input.username,
      password: this.input.password
      })
      .then(async (response) => {
      
      
      if (response.data.token) {
      const log = doc(db, 'mystudentlog', this.input.username);
      setDoc(log, {
      loggedIn: "iStudent",
      browser: "User Agent = " + navigator.userAgent,
      loggedAt: Timestamp.now(),
      }, { merge: true });
      
      this.$store.dispatch("auth/login", this.input.username).then(
      () => {
      
      this.$router.go("/");
      },
      (error) => {
      this.loading = false;
      this.message =
      (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();
      }
      );
      }else{
      this.message = response.data.msg,
      this.loading = false
      }
      });*/
      
      
    },
    async handleMydigitalidLogin(){
      console.log("Connect to web socket");
      this.initializeWebSocket();
      setInterval(this.updateCountdown, 1000);
    },
    async handleGoogleLogin() {
      this.loading = true;
      
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
        hd: "student.uitm.edu.my",
      });
      
      //const auth = getAuth();
      signInWithPopup(auth, provider)
      .then((result) => {
        const log = doc(db, 'mystudentlog', result.user.email.split("@")[0]);
        setDoc(log, {
          loggedIn: "Google",
          browser: "User Agent = " + navigator.userAgent,
          loggedAt: Timestamp.now(),
        }, { merge: true });
        
        
        this.$store.dispatch("auth/login", result.user.email.split("@")[0]).then(
        (result) => {
          if (result){
            
            this.$router.go("/");
          }
          
          else
          this.$router.push("/login");
        },
        (error) => {
          this.loading = false;
          this.message =
          (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message.replace('Firebase:', '') ||
          error.toString().replace('Firebase:', '');
        }
        );
      }).catch((error) => {
        this.loading = false;
        this.message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message.replace('Firebase:', '') ||
        error.toString().replace('Firebase:', '');
      });
      
      
      
      // try {
      // result= await setPersistence(auth, browserSessionPersistence)
      // .then(() => {
      //     signInWithPopup(auth, new GoogleAuthProvider());
      // })
      // .catch((error) => {
      //     // Handle Errors here.
      //     console.log(error.code);
      // });
      
      // } catch (err) {
      // throw new Error(err);
      // }
      
      // if (result) {
      
      //     this.$store.dispatch("auth/login", result.user.email.split("@")[0]).then(
      //         () => {
      //         this.$router.go("/");
      //         },
      //         (error) => {
      //         this.loading = false;
      //         this.message =
      //             (error.response &&
      //             error.response.data &&
      //             error.response.data.message) ||
      //             error.message ||
      //             error.toString();
      //         }
      //     );
      
      // }
    },
    async handleLoginSpecial () {
      this.loading = true;
      //2023213078
      //030912020395
      
      this.docSnap = await getDoc(doc(db, "studentKolej", `${this.auth.studentno}`));
      if (this.docSnap.exists()) {
        let ic_no = this.docSnap.data().app_newicnum
        //let gender = this.docSnap.data().app_gender
        let lastpage = localStorage.getItem('mystudent-from') || false;
        console.log(lastpage)
        if (ic_no.substr(ic_no.length - 6) == this.auth.icno){
          const log = doc(db, 'mystudentlog', this.auth.studentno);
          setDoc(log, {
            loggedIn: "Special",
            browser: "User Agent = " + navigator.userAgent,
            loggedAt: Timestamp.now(),
          }, { merge: true });
          this.$store.dispatch("auth/login", this.auth.studentno).then(
          () => {
            /*if(gender == 'P')
            this.$router.push({ path: "/checkin/ML"});
            else if(gender == 'L')
            this.$router.push({ path: "/checkin/PR"});
            else*/
            if(lastpage == false){
              this.$router.go("/");
              
            }else{
              this.$router.push({ path: lastpage });
            }
          },
          (error) => {
            this.loading = false;
            this.message =
            (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message.replace('Firebase:', '') ||
            error.toString().replace('Firebase:', '');
          }
          );
        }else{
          this.loading = false;
          this.message = "Maklumat yang dimasukkan tidak tepat. Sila cuba sekali lagi. Pastikan anda memasukan 6 digit terakhir no. kad pengenalan"
        }
      } else {
        this.loading = false;
        this.message = "Maklumat tidak dijumpai"
      }
      
      
    },
  }
}
</script>
<style scoped>
.container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
}

.logo {
  margin-bottom: 20px;
}

.logo img {
  max-width: 100px;
}

h1 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
}

.notification {
  background-color: #555;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  border-radius: 5px;
}

.notification span {
  font-weight: bold;
}

.qr-code {
  margin: 20px 0;
  text-align: -webkit-center;
}

.qr-code img {
  width: 200px;
  height: 200px;
}

.links {
  font-size: 14px;
  color: #007bff;
  margin-top: 20px;
}

.links a {
  color: #007bff;
  text-decoration: none;
  margin: 0 10px;
}

.links a:hover {
  text-decoration: underline;
}
</style>