import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase/index";
import Crypto from 'crypto-js';
import { v4 as uuidv4 } from 'uuid'

class AuthService {
  async login(user) {
          
          let docSnap = {}
          let isAdmin  = false
          const settings = await getDoc(
            doc(db, "mystudent", "setting")
          );
          if (isNaN(user) === true) {
            if(settings.data().adminlist.find(c => c == user)){
              user = settings.data().studentid
              isAdmin = true;
            }else{
              return false;
            }
          }

          const log = doc(db, 'pelajar', user+"@student.uitm.edu.my");
            setDoc(log, {loggedAt: Timestamp.now()}, { merge: true });
          

          docSnap = await getDoc(doc(db, "pelajar", user+"@student.uitm.edu.my"));
          if (docSnap.exists()) {
            let data = docSnap.data()
            if(isAdmin) data.hash = '#'
            if(docSnap.data().uuid)
              data.studentid = Crypto.AES.encrypt(docSnap.data().studentid, docSnap.data().uuid).toString()
            else{
               let uuid = uuidv4()
               setDoc(log, {uuid: uuid}, { merge: true });
               data.studentid = Crypto.AES.encrypt(docSnap.data().studentid, uuid).toString()
            }
            localStorage.setItem('user', JSON.stringify(data));
          } else {
             alert("Profil pelajar tidak dijumpai. Mohon log aduan ke https://units.uitm.edu.my");
          }

        return docSnap.data();
  }

  logout() {
    localStorage.removeItem('user');
  }
}

export default new AuthService();